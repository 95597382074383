import { SiteTitle, SiteTitles } from '@components/header/Header'
import Logo from '@components/header/Logo'
import Layout from '@layouts/main'
import React from 'react'

// tslint:disable-next-line:no-any
const HomePage: React.SFC = (props: any) => (
  <Layout>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        gap: '0.75rem',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <Logo width={140} />
        <SiteTitles>
          <SiteTitle>Hoekman</SiteTitle>
          <SiteTitle>Tweewielerspecialist</SiteTitle>
        </SiteTitles>
      </div>
      <p
        style={{
          fontSize: '1.5rem',
          fontWeight: 900,
          color: '#000',
          marginTop: '1rem',
        }}
      >
        Definitief gesloten
      </p>
    </div>
  </Layout>
)

export default HomePage
