import { Styled, Theme } from '@layouts/theme';

interface IProps {
  color?: string;
  bgColor?: string;
  theme?: typeof Theme;
}

export const Button = Styled('a')(({ theme, color = 'black', bgColor = 'transparent' }: IProps) => ({
  color: theme.colors[color],
  backgroundColor: theme.colors[bgColor],
  fontWeight: theme.fontWeights.semibold,
  cursor: 'pointer',
  outline: 'none',
  border: 0,
  userSelect: 'none',
  padding: `${theme.padding['3']} ${theme.padding['4']}}`,
}));

export const ToggleButton = Styled(Button)(({ theme, toggled, color = 'black', bgColor = 'transparent' }: any) => ({
  color: toggled ? theme.colors.white : theme.colors[color],
  outline: toggled ? '0' : `${theme.borderWidths[2]} solid ${theme.colors[bgColor]}`,
  outlineOffset: -2,
  backgroundColor: toggled ? theme.colors[bgColor] : theme.colors.transparent,
}));

export const FullButton = Styled(Button)(({ theme }) => ({
  width: theme.width.full,
}));
