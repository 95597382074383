import SEO from '@components/SEO'
import { css, Global } from '@emotion/core'
import { Styled, Theme } from '@layouts/theme'
import { mq } from '@quido/theme/dist/helpers'
import { ThemeProvider } from 'emotion-theming'
import * as React from 'react'

const Page = Styled('div')(({ theme }) => ({
  fontFamily: theme.fonts.sans,
  width: theme.width.full,
  minHeight: theme.minHeight.full,
  position: 'relative',
}))

const Container = Styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: theme.width.full,
  }),
  ({ theme }) =>
    mq.xs(
      css({
        minHeight: 'calc(100vh - 233px)',
        marginBottom: theme.margin[12],
      })
    ),
  ({ theme }) =>
    mq.md(
      css({
        alignItems: 'center',
        margin: '0 auto',
        maxWidth: theme.screens.lg,
        marginBottom: theme.margin[8],
      })
    )
)

interface IProps {
  children: React.ReactNode
  preContent?: React.ReactNode
  postContent?: React.ReactNode
}

const MainLayout: React.SFC<IProps> = ({ children, preContent, postContent }) => (
  <ThemeProvider theme={Theme}>
    <Global
      styles={{
        'html, body, #___gatsby, #___gatsby > div ': {
          margin: 0,
          minHeight: '100%',
          display: 'flex',
          flex: 1,
        },
        '*': {
          boxSizing: 'border-box',
        },
      }}
    />
    <SEO />
    <Page>{children}</Page>
  </ThemeProvider>
)

export default MainLayout
