import ContactDetails from '@components/header/ContactDetails'
import Logo from '@components/header/Logo'
import { MobileNavigation } from '@components/header/MobileNavigation'
import Navigation from '@components/header/Navigation'
import css from '@emotion/css'
import { Styled } from '@layouts/theme'
import { mq } from '@quido/theme/dist/helpers'
import React from 'react'

const Container = Styled('header')(
  ({ theme }) => ({
    display: 'flex',
    position: 'relative',
    padding: `${theme.padding['4']} ${theme.padding['8']}`,
    flexWrap: 'wrap',
    fontWeight: theme.fontWeights.bold,
    justifyContent: 'center',
  }),
  ({ theme }) =>
    mq.xs(
      css({
        padding: theme.padding['2'],
      })
    ),
  ({ theme }) =>
    mq.md(
      css({
        padding: theme.padding['4'],
      })
    )
)

export const SiteTitles = Styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.padding['2'],
}))

export const SiteTitle = Styled('span')(({ theme }) => ({
  fontSize: theme.textSizes.lg,
  color: theme.colors.black,
}))

const ShowOnTablet = Styled('div')(
  () => mq.xs(css({ display: 'none' })),
  () => mq.md(css({ display: 'block' }))
)

export default () => (
  <div>
    <MobileNavigation />
    <Container>
      <div>
        <Logo width={140} />
      </div>
      <SiteTitles>
        <SiteTitle>Hoekman</SiteTitle>
        <SiteTitle>Tweewielerspecialist</SiteTitle>
      </SiteTitles>
      <ContactDetails />
    </Container>
    <ShowOnTablet>
      <Navigation />
    </ShowOnTablet>
  </div>
)
