import styled, { CreateStyled } from '@emotion/styled';
import { Theme as DefaultTheme } from '@quido/theme';

export const Theme = DefaultTheme;
// Override theme
Theme.colors.primary = '#3399ff';
Theme.colors.secondary = '#323f4a';
//

export const Styled: CreateStyled<typeof Theme> = styled;
