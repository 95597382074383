import { Button } from '@components/Button';
import Navigation from '@components/header/Navigation';
import { css } from '@emotion/core';
import { Styled, Theme } from '@layouts/theme';
import { mq } from '@quido/theme/dist/helpers';
import React from 'react';
import { FaBars } from 'react-icons/fa';

interface IContainer {
  theme?: typeof Theme;
  shown: boolean;
}

const Container = Styled('div')(
  ({ theme, shown }: IContainer) => ({
    position: 'absolute',
    display: 'flex',
    background: shown ? theme.colors.primary : theme.colors.transparent,
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',
    boxShadow: shown ? theme.shadows.lg : 'none',
    paddingBottom: theme.padding['6'],
    zIndex: 60,
  }),
  () => mq.md(css({ display: 'none' }))
);

const Menu = Styled('div')(({ theme }) => ({
  width: theme.width.full,
  '> div': {
    background: theme.colors.transparent,
    boxShadow: 'none',
  },
}));

const Icon = Styled(FaBars)(({ theme, shown: boolean }) => ({
  fontSize: theme.textSizes['2xl'],
  paddingLeft: theme.padding['2'],
}));

interface IState {
  shown: boolean;
}

export class MobileNavigation extends React.Component<any, IState> {
  public state = {
    shown: false,
  };
  public render() {
    const { shown } = this.state;
    const buttonColor = shown ? 'white' : 'primary';

    return (
      <Container shown={shown}>
        <Button onClick={this.handleClick} color={buttonColor}>
          <Icon color={Theme.colors[buttonColor]} />
        </Button>
        {shown && (
          <Menu>
            <Navigation />
          </Menu>
        )}
      </Container>
    );
  }

  private handleClick = () => {
    this.setState({ shown: !this.state.shown });
  }
}
