import css from '@emotion/css';
import { Styled } from '@layouts/theme';
import { mq } from '@quido/theme/dist/helpers';
import React from 'react';

export const Container = Styled('div')(({ theme }) => ({
  display: 'flex',
  padding: `0 ${theme.padding['8']}`,
  background: theme.colors.primary,
  boxShadow: theme.shadows.lg,
  zIndex: 50,
}))

const Navigation = Styled('nav')(
  () => ({
    display: 'flex',
  }),
  () =>
    mq.xs(
      css({
        flexDirection: 'column',
        flex: 1,
      })
    ),
  () =>
    mq.md(
      css({
        flexDirection: 'row',
      })
    )
)

const Link = Styled('a')(
  ({ theme }) => ({
    display: 'block',
    alignSelf: 'flex-end',
    color: theme.colors.white,
    fontSize: theme.textSizes.sm,
    fontWeight: theme.fontWeights.semibold,
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:hover': {
      background: theme.colors.black,
      color: theme.colors.white,
    },
  }),
  ({ theme }) =>
    mq.xs(
      css({
        width: '100%',
        textAlign: 'center',
        padding: `${theme.padding['2']} 0`,
      })
    ),
  ({ theme }) =>
    mq.md(
      css({
        width: 'auto',
        textAlign: 'left',
        padding: `${theme.padding['2']} ${theme.padding['4']}`,
      })
    )
)

export default () => (
  <Container>
    <Navigation>
      <Link href="/">Home</Link>
      <Link href="/nieuws">Nieuws</Link>
      <Link href="/custom-build">Custom Build</Link>
      <Link href="/contact">Contact</Link>
    </Navigation>
  </Container>
)
