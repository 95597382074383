import css from '@emotion/css';
import { Styled } from '@layouts/theme';
import { mq } from '@quido/theme/dist/helpers';
import React from 'react';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { MdPayment } from 'react-icons/md';

const Container = Styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
  }),
  ({ theme }) =>
    mq.xs(
      css({
        marginTop: theme.margin[2],
        marginLeft: theme.margin[2],
        padding: 0,
      })
    ),
  ({ theme }) =>
    mq.md(
      css({
        padding: `0 ${theme.padding['3']} ${theme.padding['2']}`,
        margin: 0,
      })
    )
);

const Item = Styled('div')(() => ({}));

const Icon = Styled('i')(({ theme }) => ({
  color: theme.colors.primary,
  paddingLeft: theme.padding['2'],
  fontSize: theme.textSizes.lg,
}));

const Link = Styled('a')(
  ({ theme }) => ({
    color: theme.colors.black,
    paddingLeft: theme.padding['2'],
    width: theme.width.full,
    textDecoration: 'none',
  }),
  ({ theme }) =>
    mq.xs(
      css({
        fontSize: theme.textSizes.xs,
      })
    )
);

const PhoneIcon = Icon.withComponent(FaPhone);
const EmailIcon = Icon.withComponent(FaEnvelope);
const PaymentIcon = Icon.withComponent(MdPayment);
const LocationIcon = Icon.withComponent(FaMapMarkerAlt);

export default () => (
  <Container>
    <Item>
      <PhoneIcon />
      <Link href="tel://0555332314">055-5332314</Link>
    </Item>
    <Item>
      <EmailIcon />
      <Link href="mailto://info@hoekman-atb.nl">info@hoekman-atb.nl</Link>
    </Item>
    <Item>
      <PaymentIcon />
      <Link href="#">NL23 INGB 0658 7095 34</Link>
    </Item>
    <Item>
      <LocationIcon />
      <Link
        href="https://www.google.nl/maps/place/Hoekman+Tweewielerspecialist/@52.2431193,5.9562083,17z/data=!3m1!4b1!4m5!3m4!1s0x47c7c7a58756f0b5:0xd7c77565ea3f3ae3!8m2!3d52.243116!4d5.9584024"
        target="_blank"
      >
        Oude Zwolseweg 47, 7345BB Wenum-Wiesel
      </Link>
    </Item>
  </Container>
);
