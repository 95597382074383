import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

interface IProps {
  site: {
    siteMetadata: {
      siteName: string
      siteLanguage: string
      siteLogo: string
      siteURL: string
      siteDescription: string
      ogLanguage: string
      ogSiteName: string
      siteFBAppId: string
      userTwitter: string
    }
  }
}

const Metadata: React.SFC<IProps> = (data) => (
  <Helmet>
    <html lang={data.site.siteMetadata.siteLanguage} />
    <title>{data.site.siteMetadata.siteName}</title>
    <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover" />
    <link href="https://fonts.googleapis.com/css?family=Noto+Serif" rel="stylesheet" />

    <meta name="description" content={data.site.siteMetadata.siteDescription} />

    {/* <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script> */}
  </Helmet>
)

const SEO: React.SFC = () => <StaticQuery query={query} render={Metadata} />

const query = graphql`
  query SEOQuery {
    site {
      siteMetadata {
        siteName
        siteLanguage
        siteDescription
        siteURL
      }
    }
  }
`

export default SEO
